/******* Customized Bootstrap ********/

$primary: #d92d27;
$light: #f6f7f8;
$dark: #1d1d1b;

$font-family-base: 'Open Sans', sans-serif;

$headings-font-family: 'Roboto', sans-serif;

$body-color: #214f4b;

$dropdown-link-color: #212529;

$headings-color: $dark;

$headings-font-weight: 700;

$display-font-weight: 900;

$enable-responsive-font-sizes: true;

$border-radius: 8px;

$link-decoration: none;

$enable-negative-margins: true;

@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';

$spacers: map-merge(
    $spacers,
    (
        10: $spacer * 10
    )
);

@import '~bootstrap/scss/bootstrap.scss';
