/********** Template CSS **********/
:root {
    --primary: #d92d27;
    --light: #f6f7f8;
    --dark: #1a2a36;
}

.fw-medium {
    font-weight: 500 !important;
}

.fw-bold {
    font-weight: 700 !important;
}

.fw-black {
    font-weight: 900 !important;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 99;
    transition: 0.5s;
}

.back-to-top.hide {
    opacity: 0;
    transition: 0.5s;
}

/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity 0.5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}

/*** Button ***/
.btn {
    font-weight: 500;
    transition: 0.5s;
}

.btn.btn-primary,
.btn.btn-outline-primary:hover {
    color: #ffffff;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-lg-square.rounded-circle {
    min-width: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
}

/*** Navbar ***/
.navbar.sticky-top {
    top: -100px;
    transition: 0.5s;
}

.navbar.sticky-top.active {
    top: 0;
    transition: 0.5s;
}

.navbar .navbar-brand,
.navbar a.btn {
    height: 75px;
}

.navbar a.btn.btn-collapse {
    height: unset;
}

.navbar .navbar-nav .nav-link {
    margin-right: 30px;
    padding: 25px 0;
    color: var(--dark);
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
    color: var(--primary);
}

.navbar .dropdown-toggle::after {
    border: none;
    content: '\f107';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
}

@media (max-width: 991.98px) {
    .navbar .navbar-nav .nav-link {
        margin-right: 0;
        padding: 10px 0;
    }

    .navbar .navbar-nav {
        border-top: 1px solid #eeeeee;
    }
}

@media (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        border: none;
        border-radius: 0 0 8px 8px;
        margin-top: 0;
        top: 150%;
        opacity: 0;
        visibility: hidden;
        transition: 0.5s;
    }

    .navbar .nav-item:hover .dropdown-menu {
        top: 100%;
        visibility: visible;
        transition: 0.5s;
        opacity: 1;
    }
}

/*** Header ***/
.carousel-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.1);
}

.header-carousel h1 {
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.header-carousel .img-fluid {
    width: 100%;
    height: calc(100vh - 75px - 38px);
    object-fit: cover;
}

.header-carousel .vertical-dots {
    right: 80px;
    list-style: none;
    position: absolute;
    top: 40%;
    margin-top: -10px;
    text-align: right;
}

.header-carousel .vertical-dots li {
    position: relative;
    width: 60px;
    height: 60px;
    cursor: pointer;
    margin: 5px 0;
}

.header-carousel .vertical-dots li img {
    position: absolute;
    width: 45px;
    height: 45px;
    object-fit: cover;
    padding: 2px;
    border-radius: 45px;
    transition: 0.5s;
    margin-left: 7.5px;
    margin-top: 7.5px;
}

.header-carousel .vertical-dots li.slick-active img {
    width: 60px;
    height: 60px;
    margin: 0;
}

@media (max-width: 768px) {
    .header-carousel .owl-carousel-item {
        position: relative;
        min-height: 500px;
    }

    .header-carousel .owl-carousel-item img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .header-carousel .owl-carousel-item p {
        font-size: 16px !important;
    }
}

.header-carousel .owl-dots {
    position: absolute;
    width: 60px;
    height: 100%;
    top: 0;
    right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header-carousel .owl-dots .owl-dot {
    position: relative;
    width: 45px;
    height: 45px;
    margin: 5px 0;
    background: #ffffff;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.9);
    border-radius: 45px;
    transition: 0.5s;
}

.header-carousel .owl-dots .owl-dot.active {
    width: 60px;
    height: 60px;
}

.header-carousel .owl-dots .owl-dot img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 2px;
    border-radius: 45px;
    transition: 0.5s;
}

/*** banner header ***/
.page-header {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        url(./images/slider/carousel-1.jpg) center center no-repeat;
    background-size: cover;
}

.page-header-panneau {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        url(./images/banner-panneau.jpg) center center no-repeat;
    background-size: cover;
}

.page-header-isolation {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        url(./images/produits/isolation/isolation.png) no-repeat;
    background-size: cover;
    background-position: 0 20%;
}

.page-header-pac {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(./images/banner-pac.jpg) no-repeat;
    background-size: cover;
    background-position: 0 65%;
}

.page-header-ecs {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        url(./images/banner-ballon.jpg) center center no-repeat;
    background-size: cover;
}

.page-header-fixation {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        url(./images/banner-fixation.jpg) center center no-repeat;
    background-size: cover;
}

.page-header-chargeur {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        url(./images/banner-chargeur.jpg) center center no-repeat;
    background-size: cover;
}

.page-header-onduleur {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        url(./images/banner-onduleur.jpg) center center no-repeat;
    background-size: cover;
}

.page-header-eligibilite {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        url(./images/banner-eligibilite.jpg) center center no-repeat;
    background-size: cover;
}

.page-header-sav {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        url(./images/banner-sav.jpg) center center no-repeat;
    background-size: cover;
}

.page-header-contact {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        url(./images/banner-contact.jpg) center center no-repeat;
    background-size: cover;
}

.page-header-about {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        url(./images/banner-about.jpg) center center no-repeat;
    background-size: cover;
}

.page-header-installations {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        url(./images/banner-installations.jpg) center center no-repeat;
    background-size: cover;
}

.breadcrumb-item + .breadcrumb-item::before {
    color: var(--light);
}

/*** Product Card ***/
.product-card {
    width: 180px;
    height: 180px;
    transition: 0.1s;
}

.product-card i {
    font-size: 4em;
    transition: 0.1s;
}

.product-card:hover {
    cursor: pointer;
    color: var(--primary);
    transition: 0.1s;
}

.product-card:hover i {
    font-size: 4.5em;
    transition: 0.1s;
}

.product-card span {
    font-size: 1.25rem;
}

@media screen and (max-width: 768px) {
    .product-card {
        min-width: 140px;
        width: calc(100% / 2 - 1.5rem);
        height: 140px;
    }

    .product-card i {
        font-size: 3em;
    }

    .product-card:hover i {
        font-size: 3.5em;
    }

    .product-card span {
        font-size: 1rem;
    }
}

/*** Step Card ***/
.step-card {
    width: 210px;
    height: 210px;
    transition: 0.1s;
    margin: auto;
}

.step-card i {
    font-size: 4em;
    transition: 0.1s;
}

.step-card:hover {
    cursor: pointer;
    color: var(--primary);
    transition: 0.1s;
}

.step-card:hover i {
    font-size: 4.5em;
    transition: 0.1s;
}

.step-card span {
    font-size: 1.25rem;
}

@media screen and (max-width: 768px) {
    .step-card {
        width: 250;
        height: auto;
        margin-bottom: 1em;
        margin-top: 1em;
    }

    .step-card i {
        font-size: 3em;
    }

    .step-card:hover i {
        font-size: 3.5em;
    }

    .step-card span {
        font-size: 1rem;
    }

    .break-line {
        flex-basis: 100%;
        height: 0;
    }
}

/*** About ***/
@media (min-width: 992px) {
    .container.about {
        max-width: 100% !important;
    }

    .about-text {
        padding-right: calc(((100% - 960px) / 2) + 0.75rem);
    }
}

@media (min-width: 1200px) {
    .about-text {
        padding-right: calc(((100% - 1140px) / 2) + 0.75rem);
    }
}

@media (min-width: 1400px) {
    .about-text {
        padding-right: calc(((100% - 1320px) / 2) + 0.75rem);
    }
}

/*** Service ***/
.service-item {
    box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
}

.service-icon {
    position: relative;
    margin: -50px 0 25px 0;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    background: #ffffff;
    border-radius: 100px;
    box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
    transition: 0.5s;
}

.service-item:hover .service-icon {
    color: #ffffff;
    background: var(--primary);
}

/*** Feature ***/
@media (min-width: 992px) {
    .container.feature {
        max-width: 100% !important;
    }

    .feature-text {
        padding-left: calc(((100% - 960px) / 2) + 0.75rem);
    }
}

@media (min-width: 1200px) {
    .feature-text {
        padding-left: calc(((100% - 1140px) / 2) + 0.75rem);
    }
}

@media (min-width: 1400px) {
    .feature-text {
        padding-left: calc(((100% - 1320px) / 2) + 0.75rem);
    }
}

/*** Project Portfolio ***/
#portfolio-flters li {
    display: inline-block;
    font-weight: 500;
    color: var(--dark);
    cursor: pointer;
    transition: 0.5s;
    border-bottom: 2px solid transparent;
}

#portfolio-flters li:hover,
#portfolio-flters li.active {
    color: var(--primary);
    border-color: var(--primary);
}

.portfolio-img {
    position: relative;
}

.portfolio-img::before,
.portfolio-img::after {
    position: absolute;
    content: '';
    width: 0;
    height: 100%;
    top: 0;
    background: var(--dark);
    transition: 0.5s;
}

.portfolio-img::before {
    left: 50%;
}

.portfolio-img::after {
    right: 50%;
}

.portfolio-item:hover .portfolio-img::before {
    width: 51%;
    left: 0;
}

.portfolio-item:hover .portfolio-img::after {
    width: 51%;
    right: 0;
}

.portfolio-img img {
    width: 600px;
    height: 400px;
}

.home-projects .portfolio-img img {
    width: 100%;
    height: auto;
}

.portfolio-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    z-index: 1;
    transition: 0.5s;
}

.portfolio-item:hover .portfolio-btn {
    opacity: 1;
    transition-delay: 0.3s;
}

/*** Quote ***/
@media (min-width: 992px) {
    .container.quote {
        max-width: 100% !important;
    }

    .quote-text {
        padding-right: calc(((100% - 960px) / 2) + 0.75rem);
    }
}

@media (min-width: 1200px) {
    .quote-text {
        padding-right: calc(((100% - 1140px) / 2) + 0.75rem);
    }
}

@media (min-width: 1400px) {
    .quote-text {
        padding-right: calc(((100% - 1320px) / 2) + 0.75rem);
    }
}

/*** Team ***/
.team-item {
    box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
}

.team-item img {
    border-radius: 8px 60px 0 0;
}

.team-item .team-social {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background: #ffffff;
    transition: 0.5s;
}

/*** Testimonial ***/
/* .testimonial-carousel::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 1;
}

.testimonial-carousel::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    height: 100%;
    width: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 1;
} */

.testimonial-carousel .slick-dots {
    bottom: -50px;
}

@media (min-width: 768px) {
    .testimonial-carousel::before,
    .testimonial-carousel::after {
        width: 200px;
    }
}

@media (min-width: 992px) {
    .testimonial-carousel::before,
    .testimonial-carousel::after {
        width: 300px;
    }
}

.testimonial-carousel .owl-nav {
    position: absolute;
    width: 350px;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    opacity: 0;
    transition: 0.5s;
    z-index: 1;
}

.testimonial-carousel:hover .owl-nav {
    width: 300px;
    opacity: 1;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
    position: relative;
    color: var(--primary);
    font-size: 45px;
    transition: 0.5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
    color: var(--dark);
}

.testimonial-carousel .testimonial-img img {
    width: 100px;
    height: 100px;
}

.testimonial-carousel .testimonial-img .btn-square {
    position: absolute;
    bottom: -19px;
    left: 50%;
    transform: translateX(-50%);
}

.testimonial-carousel .owl-item .testimonial-text {
    margin-bottom: 30px;
    box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
    transform: scale(0.8);
    transition: 0.5s;
}

.testimonial-carousel .owl-item.center .testimonial-text {
    transform: scale(1);
}

/*** Contact ***/
@media (min-width: 992px) {
    .container.contact {
        max-width: 100% !important;
    }

    .contact-text {
        padding-left: calc(((100% - 960px) / 2) + 0.75rem);
    }
}

@media (min-width: 1200px) {
    .contact-text {
        padding-left: calc(((100% - 1140px) / 2) + 0.75rem);
    }
}

@media (min-width: 1400px) {
    .contact-text {
        padding-left: calc(((100% - 1320px) / 2) + 0.75rem);
    }
}

/*** Footer ***/
.footer .footer-address .btn.btn-link {
    color: #9b9b9b;
    font-weight: normal;
    text-transform: capitalize;
    transition: 0.3s;
}
.footer .footer-address .btn.btn-link:hover {
    color: #ffffff;
    letter-spacing: 0.2px;
}

.footer .btn.btn-social {
    margin-right: 5px;
    color: #9b9b9b;
    border: 1px solid #9b9b9b;
    border-radius: 38px;
    transition: 0.3s;
}

.footer .btn.btn-social:hover {
    color: var(--primary);
    border-color: var(--light);
}

.footer .footer-nav .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: #9b9b9b;
    font-weight: normal;
    text-transform: capitalize;
    transition: 0.3s;
}

.footer .footer-nav .btn.btn-link::before {
    position: relative;
    content: '\f105';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    margin-right: 10px;
}

.footer .footer-nav .btn.btn-link:hover {
    color: #ffffff;
    letter-spacing: 0.5px;
    box-shadow: none;
}

.footer .footer-nav .btn.btn-link.active {
    color: #ffffff;
    letter-spacing: 0.5px;
    box-shadow: none;
}

.footer .copyright {
    padding: 25px 0;
    border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
    color: var(--light);
}

.footer .copyright a:hover {
    color: var(--primary);
}

/*** Phone number input ***/
.PhoneInputInput {
    border: none;
}
