.custom-popup .mapboxgl-popup {
    background-color: #d92d27;
    border: none;
    box-shadow: none;
    pointer-events: none;
}

.custom-popup .mapboxgl-popup-tip {
    display: none;
}

.custom-popup .mapboxgl-popup-content {
    color: white;
    font-weight: normal;
    pointer-events: none;
    background-color: #d92d27;
    padding: 5px;
    line-height: 100%;
}

/* Style de base pour les cartes */
.card-option {
    text-align: center;
    padding: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Centrer le texte verticalement dans la carte */
.card-option .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ajuster la hauteur pour centrer verticalement */
}

/* Style au survol */
.card-option:hover {
    background-color: #f0f0f0;
}

/* Style pour la sélection */
.card-option.selected {
    background-color: #d92d27;
    color: #fff;
    border-color: #d92d27;
}

/* Style pour l'icône */
.card-option i {
    font-size: 2rem;
}

/* Style pour le texte */
.card-option .card-title {
    margin-top: 0.5rem;
    font-weight: bold;
}

/* Style pour l'icône lorsqu'il est sélectionné */
.card-option.selected i {
    color: #fff;
}

@keyframes lineFillAnim {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

.confirmation-container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}
.confirmation-container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.confirmation-help-text {
    font-size: 0.8rem;
}
